import React, { useState } from "react";
// import { Fade, Slide } from "react-reveal";
import emailjs from "@emailjs/browser";

function Contact(props) {
  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [senderSubject, setSenderSubject] = useState("");
  const [senderMessage, setSenderMessage] = useState("");

  if (!props.data) return null;

  const name = props.data.name;
  const street = props.data.address.street;
  const city = props.data.address.city;
  const state = props.data.address.state;
  const zip = props.data.address.zip;
  const phone = props.data.phone;
  const message = props.data.contactmessage;

  const handleEmailClick = (e) => {
    e.preventDefault();
    const response = {
      contactName: senderName,
      contactEmail: senderEmail,
      contactSubject: senderSubject,
      contactMessage: senderMessage,
    };
    emailjs.init("o6xhEJXPTdPiqz-zR");
    emailjs
      .send(
        "service_epp8yob",
        "template_xpstb5o",
        response,
        "o6xhEJXPTdPiqz-zR"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };

  return (
    <section id="contact">
      {/* <Fade bottom duration={1000}> */}
      <div className="row section-head">
        <div className="two columns header-col">
          <h1>
            <span>Get In Touch.</span>
          </h1>
        </div>

        <div className="ten columns">
          <p className="lead">{message}</p>
        </div>
      </div>
      {/* </Fade> */}

      <div className="row">
        {/* <Slide left duration={1000}> */}
        <div className="eight columns">
          <form
            action=""
            method="post"
            id="contactForm"
            name="contactForm"
            onSubmit={handleEmailClick}
          >
            <fieldset>
              <div>
                <label htmlFor="contactName">
                  Name <span className="required">*</span>
                </label>
                <input
                  value={senderName}
                  type="text"
                  defaultValue=""
                  size="35"
                  id="contactName"
                  name="contactName"
                  onChange={(e) => setSenderName(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="contactEmail">
                  Email <span className="required">*</span>
                </label>
                <input
                  value={senderEmail}
                  type="text"
                  defaultValue=""
                  size="35"
                  id="contactEmail"
                  name="contactEmail"
                  onChange={(e) => setSenderEmail(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="contactSubject">Subject</label>
                <input
                  value={senderSubject}
                  type="text"
                  defaultValue=""
                  size="35"
                  id="contactSubject"
                  name="contactSubject"
                  onChange={(e) => setSenderSubject(e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="contactMessage">
                  Message <span className="required">*</span>
                </label>
                <textarea
                  value={senderMessage}
                  cols="50"
                  rows="15"
                  id="contactMessage"
                  name="contactMessage"
                  onChange={(e) => setSenderMessage(e.target.value)}
                ></textarea>
              </div>

              <div>
                <button
                  className="submit"
                  onClick={handleEmailClick}
                  disabled={
                    !(
                      senderName &&
                      senderEmail &&
                      senderSubject &&
                      senderMessage
                    )
                  }
                >
                  Submit
                </button>
                <span id="image-loader">
                  <img alt="" src="images/loader.gif" />
                </span>
              </div>
            </fieldset>
          </form>

          <div id="message-warning"> Error boy</div>
          <div id="message-success">
            <i className="fa fa-check"></i>Your message was sent, thank you!
            <br />
          </div>
        </div>
        {/* </Slide> */}

        {/* <Slide right duration={1000}> */}
        <aside className="four columns footer-widgets">
          <div className="widget widget_contact">
            <h4>Address and Phone</h4>
            <p className="address">
              {name}
              <br />
              {street} <br />
              {city}, {state} {zip}
              <br />
              <span>{phone}</span>
            </p>
          </div>
        </aside>
        {/* </Slide> */}
      </div>
    </section>
  );
}

export default Contact;
